import { Box, Grid } from '@mui/material'
import React from 'react'
import "./style.css"
import Image1 from "../../assets/images/logistics/Product-UAE.webp"
import imaga1 from "../../assets/images/logistics/uae.png"

import Image2 from "../../assets/images/logistics/Product-KSA.webp"
import imaga2 from "../../assets/images/logistics/ksa.webp"

import Image3 from "../../assets/images/logistics/Product-MEX.webp"
import imaga3 from "../../assets/images/logistics/mex.png"

import Image4 from "../../assets/images/logistics/Product-JOR.webp"
import imaga4 from "../../assets/images/logistics/jor.png"

import Image5 from "../../assets/images/logistics/Product-OMN.webp"
import imaga5 from "../../assets/images/logistics/omn.png"

import Image6 from "../../assets/images/logistics/Product-KWT.webp"
import imaga6 from "../../assets/images/logistics/kwt.png"

import Image7 from "../../assets/images/logistics/Product-QAT.webp"
import imaga7 from "../../assets/images/logistics/qat.png"

import Image8 from "../../assets/images/logistics/Product-BHR.webp"
import imaga8 from "../../assets/images/logistics/bhr.png"
import { Link } from 'react-router-dom'



export default function Products() {
    let data = [
        {
            img: Image1,
            countryIMG: imaga1,
            title: "UAE",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image2,
            countryIMG: imaga2,
            title: "KSA",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image3,
            countryIMG: imaga3,
            title: "MEX",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image4,
            countryIMG: imaga4,
            title: "JOR",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image5,
            countryIMG: imaga5,
            title: "OMN",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image6,
            countryIMG: imaga6,
            title: "KWT",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image7,
            countryIMG: imaga7,
            title: "QAT",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
        {
            img: Image8,
            countryIMG: imaga8,
            title: "BHR",
            p: "One-stop Logistics Solution",
            link: "Read More",
        },
    ]
    return (
        <Box className="logistic" sx={{ padding: { xs: "50px 25px", lg: "100px" } }}>

            <h1 className='h1'>Logistics Products</h1>
            <Grid container className='d-flex gap-10 justify-center' >

                {
                    data.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={5.8} lg={2.5} key={index} className='hover:shadow-lg transition duration-500 p-3 pt-5 pb-5 ' sx={{borderRadius:3}}>

                                <img src={item.img} alt="..."  />
                                <Box sx={{display:"flex" , alignItems:"center"}} >
                                    <img src={item.countryIMG} alt="..." style={{width:"80px" , margin:"0px"}} />
                                    <h1 className='font-bold'>{item.title}</h1>
                                </Box>
                                <p style={{color:"#5B5C73" , margin:"20px 0px "}}>{item.title} {item.p}</p>
                                <Link to="#" style={{color:"rgb(9, 94, 247)"}}>{item.link}</Link>

                            </Grid>
                        )
                    })
                }

            </Grid>

        </Box>)
}
