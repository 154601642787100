import { Box, Button } from '@mui/material'
import React from 'react'
import WhatWeDoo from "../../components/what-we-do/WhatWeDo"
import Support from '../../components/what-we-do/Support'
import Why from '../../components/Why'
import Index from '../../components/Index'
import image from "../../assets/images/what-we-do/banner.png"
import mobile from "../../assets/images/what-we-do/banner_mobile.png"

export default function WhatWeDo() {
    let data = {
        img: image,
        mobile : mobile,
        title1: "A One-Stop For All Your Logistics",
        title2: " Requirements",
        p1: "Requirements",
        p2: " logistics solutions to all our clients",
        direction: "right"
    }
    let data2 = [
        {

            title :"Premium performance" , 
            description : "Our clients, big and small, will have all their logistics needs met as we provide customized processes."


        },
        {

            title :"Transparency" , 
            description : "Our digitized processes and client portals give complete visibility on the performance of your business."

        },
        {

            title :"Efficiency" , 
            description :"Our in-house technology ensures enhanced customer and client experience with exceptional performance."

        },
    ]
    return (
        <Box sx={{paddingTop:'50px'}}>
            <Index data={data} />
            <WhatWeDoo />
            <Box className="contact p-6" sx={{ backgroundColor: " rgb(230, 230, 239)", display: "flex", justifyContent: { xs: "center", lg: "space-between", gap: "20px", alignItems: "center" }, flexWrap: "wrap" }}>
                <h1 style={{ fontSize: "25px", fontWeight: "bold" }}>Are you interested to find out more?
                </h1>
                <Button sx={{ color: "white", borderRadius: 4, pl: 3, pr: 3, pt: 2, pb: 2, backgroundColor: "rgb(9, 94, 247)" }}>Contact US</Button>
            </Box>
            <Support />
            <Why data2={data2} />
        </Box>
    )
}
