import { Box } from '@mui/material'
import React from 'react'
import "./style.css"
export default function Find() {
    return (
        <Box className="find" sx={{ padding: { xs: "50px 25px", lg: "100px" } }}>
            <h1>Find Out More</h1>
            <iframe
                src="https://www.youtube.com/embed/XetuTq6dLp4"
                frameBorder="0"
                className="w-full h-[50vh] lg:h-[100vh]"
            ></iframe>
            <p >We are as passionate about what we do as you are. Our clients worldwide are not only e-commerce giants but also retailers, banks, entrepreneurs, and SME’s. When you are a part of the iMile family, it’s simple, concentrate on what you do best, and we will take care of the rest. Delivery you can trust “We Got This”
            </p>
        </Box>
    )
}
 