import React from 'react'
import image from "../../assets/images/what-we-do/1_1@2x.png"
import { Box, Button, Grid } from '@mui/material'
import "./style.css"
export default function WhatWeDo() {
  let data = [
    {
      title1: "Quick COD",
      title2: "Remittance",
      p: `Customers have the option to pay
by cash instead of the card at the
time of delivery, making it easier
for them to use our services. Our
clients will receive the COD
remittance in less than a week.`
      ,
      img: image
    },
    {
      title1: "Fulfillment",
      title2: "and Warehousing",
      p: `Our facilities worldwide can
store, stock, label, fulfill, and
barcode several orders accurately
and on time. Based on our client's
requirements, we can provide all
types of warehousing and
fulfillment solutions.
`
      ,
      img: image
    },

    {
      title1: "Same Day",
      title2: "Next Day",
      p: `We provide express delivery
services where customers can
place and receive their orders
on the same day. We also offer
"Next Day Deliveries" and
"Scheduled Deliveries" depending
on the client's requirements.

`
      ,
      img: image
    },
    {
      title1: "Last-Mile",
      title2: "Next Day",
      p: `We directly pick up orders from
our domestic client’s warehouse
and deliver them to their customers.
Some clients may not have the
fleet or manpower to do last-mile deliveries, and this is where we
step in.

`
      ,
      img: image
    },
    {
      title1: "Cross Border and",
      title2: "Custom Clearance",
      p: `We provide complete 3PL solutions
internationally and regionally.
We pick-up and deliver shipments
across borders and deliver them
to the customers. We also handle
and provide express custom clearance
to move these shipments seamlessly

`
      ,
      img: image
    },
    {
      title1: "Client",
      title2: "Initiated Returns",
      p: `Client can schedule returns
directly with us without delay
with the client. They will receive a
link through which they can
schedule the pick-up.

`
      ,
      img: image
    },

  ]
  return (
    <Box className="what-we-do" sx={{ padding: { xs: "50px 25px", lg: "100px" } }}>
      <h1>What We Do
      </h1>
      <Grid container className='d-flex gap-10 justify-center p-4 '  >

        {
          data.map((item, index) => {
            return (
              <Grid item xs={12} sm={5.8} lg={3.5} key={index} className='relative bg-white shadow-lg p-5 ' sx={{ borderRadius: 5 }}>


                <h2>{item.title1}</h2>
                <h2>{item.h2 ? item.h2 : undefined}</h2>
                <p>{item.p}</p>
                <img src={item.img} alt="..." />
              </Grid>

            )
          })
        }
      </Grid>

    </Box>
  )
}
