import React, { useState } from 'react';
import { Grid, Link, Box, MenuItem } from '@mui/material';

const NavMenu = () => {
  // State to control the visibility of the submenu
  const [submenuOpen, setSubmenuOpen] = useState(false);

  // Timer to delay submenu closing
  let timer;

  // Handle showing submenu (on mouse enter)
  const handleMouseEnter = () => {
    clearTimeout(timer); // Clear any existing timer
    setSubmenuOpen(true);
  };

  // Handle hiding submenu (with a delay)
  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      setSubmenuOpen(false);
    }, 200); // Adjust delay time as needed
  };

  // Helper function to handle active link style
  const linkStyle = {
    fontSize: '18px', 
    fontWeight: 'bold', 
    position: 'relative',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none', // Remove underline on hover
    },
    '&.active': {
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -2,
        display: 'inline-block',
        background: 'rgb(255, 243, 3)', // Yellow underline color
        height: '6px',
        width: '100%',
        right: '50%',
        transform: 'translate(50%, 0)', // Center underline
      },
    },
  };

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      mb={1}
    >
      {/* Desktop View */}
      <Grid item sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <Box display="flex" gap={3} position="relative">
          <Link href="/" sx={linkStyle} className="active">
            Home
          </Link>
          <Link href="/about" sx={linkStyle}>
            About us
          </Link>

          {/* Services Link with Hover */}
          <Link 
            href="/" 
            sx={linkStyle} 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
          >
            Services
          </Link>

          {/* Submenu for "Services" */}
          {submenuOpen && (
            <Box
              sx={{
                position: 'absolute',
                top: '35px',  // Adjust position to appear directly below the link
                left: '80px',
                backgroundColor: '#fff',
                boxShadow: 3,
                minWidth: '200px',
                borderRadius: '4px',
                color:'#000',
                zIndex: 10,
              }}
              onMouseEnter={handleMouseEnter} // Keep submenu open when hovering over submenu
              onMouseLeave={handleMouseLeave} // Close submenu when mouse leaves submenu
            >
              <MenuItem component="a" href="/service/waht-we-do" sx={{ width: '100%', textAlign: 'center',justifyContent:'center' }}>
                What we do?
              </MenuItem>
              <MenuItem component="a" href="/service/logistics" sx={{ width: '100%', textAlign: 'center' ,justifyContent:'center' }}>
                Logistics Products
              </MenuItem>
              <MenuItem component="a" href="/service/international" sx={{ width: '100%', textAlign: 'center' ,justifyContent:'center' }}>
                International & China
              </MenuItem>
            </Box>
          )}

          <Link href="/technology" sx={linkStyle}>
            Technology
          </Link>
          <Link href="/csp" sx={linkStyle}>
            CSP
          </Link>
          <Link href="/track" sx={linkStyle}>
            Track orders
          </Link>
          <Link href="/blog" sx={linkStyle}>
            Blog
          </Link>
          <Link href="/login" sx={linkStyle}>
            Login
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NavMenu;
