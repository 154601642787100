import React from 'react';
import { Toolbar, Link, Select, MenuItem, Typography, Box } from '@mui/material';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const countries = [
  { code: 'ae', name: 'United Arab Emirates', icon: '🇦🇪' },
  { code: 'gb', name: 'United Kingdom', icon: '🇬🇧' },
  { code: 'nz', name: 'New Zealand', icon: '🇳🇿' },
  { code: 'es', name: 'Spain', icon: '🇪🇸' },
  { code: 'au', name: 'Australia', icon: '🇦🇺' },
  { code: 'bh', name: 'Bahrain', icon: '🇧🇭' },
  { code: 'br', name: 'Brazil', icon: '🇧🇷' },
  { code: 'cn', name: 'China', icon: '🇨🇳' },
  { code: 'it', name: 'Italy', icon: '🇮🇹' },
  { code: 'sa', name: 'Saudi Arabia', icon: '🇸🇦' },
  { code: 'kw', name: 'Kuwait', icon: '🇰🇼' },
];

const languages = [
  { code: 'en', name: 'English', icon: '🇬🇧' },
  { code: 'ar', name: 'Arabic', icon: '🇦🇪' },
  { code: 'es', name: 'Spanish', icon: '🇪🇸' },
  { code: 'fr', name: 'French', icon: '🇫🇷' },
  { code: 'de', name: 'German', icon: '🇩🇪' },
];

const TopBar = () => {
  const [selectedCountry, setSelectedCountry] = React.useState('ae');
  const [selectedLanguage, setSelectedLanguage] = React.useState('en');

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <Toolbar sx={{ justifyContent: 'flex-end', minHeight: '30px', gap: 2, paddingLeft: 0, paddingRight: 0 }}>
    <Box sx={{ display: { xs: 'none',  md: 'flex' } }}>
      {/* Box to contain the links and make sure they align correctly */}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Link
          href="tel:+971 555692321"
          color="inherit"
          underline="none"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px', // Set font size for the link
          }}
        >
          <FaPhoneAlt style={{ marginRight: 2 }} /> Call: +971 555692321
        </Link>
        <Link
          href="Partner@blinkds.com"
          color="inherit"
          underline="none"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px', // Set font size for the link
          }}
        >
          <FaEnvelope style={{ marginRight: 2 }} /> Email: Partner@blinkds.com
        </Link>
      </Box>
      {/* Box for country and language selectors aligned to the right */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {/* Country Selector */}
        {/* <Typography variant="body2" color="inherit" sx={{ fontSize: '14px' }}>
          {countries.find(country => country.code === selectedCountry)?.name}
        </Typography> */}
        <Select
          value={selectedCountry}
          onChange={handleCountryChange}
          variant="standard"
          sx={{
            color: '#fff',
            '& .MuiSvgIcon-root': { color: '#fff' },
            fontSize: '14px', // Set font size for the select dropdown
          }}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <span style={{ marginRight: 8 }}>{country.icon}</span>
              {country.name}
            </MenuItem>
          ))}
        </Select>

        {/* Language Selector */}
        {/* <Typography variant="body2" color="inherit" sx={{ fontSize: '14px' }}>
          {languages.find(language => language.code === selectedLanguage)?.name}
        </Typography> */}
        <Select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          variant="standard"
          sx={{
            color: '#fff',
            '& .MuiSvgIcon-root': { color: '#fff' },
            fontSize: '14px', // Set font size for the select dropdown
          }}
        >
          {languages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              <span style={{ marginRight: 8 }}>{language.icon}</span>
              {language.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      </Box>
    </Toolbar>
  );
};

export default TopBar;
