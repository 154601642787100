import { Box } from '@mui/material'
import React from 'react'
import Index from '../../components/home/Index'
import Why from '../../components/Why'
import Delivers from '../../components/home/Delivers '
import Find from '../../components/home/Find'
import Download from '../../components/home/Download'
import Line from '../../components/home/Line'

export default function Home() {
  let data2 = [
    {

      title: "Quick COD Remittance",
      description: `Customers have the 
 option to pay by cash 
at the time of delivery`


    },
    {

      title: "Fulfillment and Warehousing",
      description: `Our facilities worldwide 
can store, stock, label, fulfill, 
 and barcode several orders 
 accurately and on time`

    },
    {

      title: "Same Day Next Day",
      description: `We provide express delivery 
 services where customers can  
 place and receive their orders 
 on the same day`

    },
  ]
  return (
    <Box >
      <Index />
      <Line/>
      <Delivers/>
      <Find/>
      <Why data2={data2} />
      <Download/>
    </Box>
  )
}
