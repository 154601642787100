import React from 'react'
import image1 from "../../assets/images/international/air.png"
import image2 from "../../assets/images/international/sea.png"
import image3 from "../../assets/images/international/land.png"
import { Box } from '@mui/material'


export default function Movement() {
    let data = [
        {
            img: image1,
            title: "Air Transport",
            description: "We handle pick-up, packing, warehousing, customs clearance, booking, delivery, insurance, and more to ensure that customers' orders reach them safely and quickly."
        },
        {
            img: image2,
            title: "Sea Transport",
            description: "We provide Full Container Load (FCL), Less than Container Load (LCL), Roll-On/Roll-Off (Ro-Ro) ships, car consignments, bulk cargo transportation, and much more."
        },
        {
            img: image3,
            title: "Land Transport",
            description: "We provide transportation from the UAE Free Zone or domestic land to Saudi Arabia and other GCC countries. Partners can choose from bulk trucks (LTL) or standard vehicles (FTL)."
        },
    ]
    return (
        <Box className="movement" sx={{ padding: { xs: "50px 25px", lg: "100px" } }}>
            <h1>International Movement
            </h1>
            <p className='p-1'>iMile is currently present in more than eleven countries across three markets and continues to expand further into these markets.<br /> Thus, iMile can provide cross-border solutions within the countries they are in through air, sea, and land.
            </p>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", lg: "space-between" }, gap: "20px", flexWrap: "wrap" }} >
                {
                    data.map((item, index) => {
                        return (
                            <div className="max-w-sm  overflow-hidden shadow-lg" style={{ borderRadius: 23 }} key={index}>
                                <img
                                    className="w-full"
                                    src={item.img}
                                    alt="Sunset in the mountains"
                                />
                                <div >
                                    <div className=" text-xl mb-2 p-4" style={{ backgroundColor: "#095ef7", color: "white", textAlign: "center" }}>{item.title}</div>
                                    <p className="text-gray-700 text-base  px-6 py-4">
                                        {item.description}
                                    </p>
                                </div>

                            </div>

                        )
                    })
                }
            </Box>
        </Box>
    )
}
