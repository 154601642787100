import { Box } from '@mui/material'
import React from 'react'

import image from "../../assets/images/international/international1.jpg"

export default function From() {
    return (
        <Box className="form" sx={{ padding: { xs: "25px 50px 25px", lg: "0px 100px 100px" } }}>
            <h1>From China to The World

            </h1>
            <p className='p-1'>We are one of the largest shippers bridging Chinese sellers to the world with warehousing, fulfillment, and expertise in last-mile solutions in high-growth markets.

            </p>

            <img src={image} alt="..." style={{width:"100%"}} />
            <h1>Bridging The Gap</h1>
            <p className="p-1">Customers place orders on e-commerce platforms and deliver them to iMile’s South China warehouse. The operations team in South China will collect these packages and ship them by air or sea to the iMile warehouses around the world. Once the shipment has reached the warehouse, our designated drivers will deliver the orders to each customer’s doorstep, allowing our customers to have complete visibility and tracking ability from the moment the order is placed in China until it is delivered.
            </p>
        </Box>
    )
}
