import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaPhone, FaGlobe } from 'react-icons/fa';  // Import FaGlobe
import { MdEmail } from 'react-icons/md';
import './Footer.css';  // Import the CSS file

// Footer Contact Section Component
const ContactSection = ({ country, phone, email, address }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Typography className="footer-contact">{country}</Typography>
    <Typography className="footer-details">
      <FaPhone className="icon" /> {phone}
    </Typography>
    <Typography className="footer-details">
      <MdEmail className="icon" /> {email}
    </Typography>
    <Typography className="footer-details">
      <FaGlobe className="icon" /> {address}  {/* Added FaGlobe icon */}
    </Typography>
  </Grid>
);

// Footer Links Section Component
const FooterLinks = () => (
  <Grid item xs={12} sm={6} md={3} className="footer-links">
    <Typography className="footer-contact">Sign up / Login</Typography>
    <Typography className="footer-details">
      <Link href="#" underline="hover">Careers</Link>
    </Typography>
    <Typography>
      <Link href="#" underline="hover">Blog</Link>
    </Typography>
    <Typography className="footer-details">
      <Link href="#" underline="hover">Tracking</Link>
    </Typography>
  </Grid>
);

// Social Media Icons Section Component
const SocialIcons = () => (
  <Box className="social-icons">
    <Link href="#" aria-label="Facebook">
      <FaFacebookF size={25} />
    </Link>
    <Link href="#" aria-label="Twitter">
      <FaTwitter size={25} />
    </Link>
    <Link href="#" aria-label="Instagram">
      <FaInstagram size={25} />
    </Link>
    <Link href="#" aria-label="LinkedIn">
      <FaLinkedin size={25} />
    </Link>
  </Box>
);

// Main Footer Component
function Footer() {
  return (
    <Box component="footer" className="footer">
      <Typography className="footer-title">Get in Touch</Typography>
      <Typography className="footer-subtitle">We are in</Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* UAE Contact Section */}
        <ContactSection
          country="United Arab Emirates"
          phone="+971 555692321"
          email="Partner@blinkds.com"
          address="302B Ibn batuta gate offices, Jebel Ali, Dubai, UAE"
        />

        {/* Saudi Arabia Contact Section */}
        <ContactSection
          country="Dubai"
          phone="+971 555692321"
          email="Partner@blinkds.com"
          address=" 302B Ibn batuta gate offices, Jebel Ali, Dubai, UAE"
        />

        {/* Mexico Contact Section */}
        <ContactSection
          country="Contact"
          phone="+971 555692321"
          email="Support@blinkds.com"
          address=""
        />

        {/* Additional Links Section */}
        <FooterLinks />
      </Grid>

      {/* Footer Bottom Section */}
      <Box className="footer-bottom">
        <Typography className="footer-contact-details">
          PR and Media Enquiries: 📧 <Link href="mailto:shraddha.pareek@imile.me">Partner@blinkds.com</Link>
        </Typography>

        <SocialIcons />

        <Typography className="footer-copyright">
          Copyright©2024 Blink Delivery Services L.L.C All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
