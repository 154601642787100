import { Box } from '@mui/material'
import React from 'react'
import Products from '../../components/logistics/Products'
import Index from '../../components/Index'
import image from "../../assets/images/logistics/logisticsProduct-banner.png"
import mobile from "../../assets/images/logistics/logisticsProduct-banner_mobile.png"

export default function Logistics() {
    let data = {
        img : image ,
        mobile : mobile,
        title1 :"One-stop Logistics",
        title2 :" Products and Solutions",
        p1 :"Discover what each of our country-based",
        p2:" logistics products has to offer.",
        direction :"right"
    }
    return (
        <Box sx={{paddingTop:'50px'}}>
            <Index data={data}/>
            <Products />
        </Box>)
}
