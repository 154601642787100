import { Box, Grid } from '@mui/material'
import React from 'react'

export default function Why(props) {

    return (
        <Box className="why" sx={{ padding: { xs: "0px 25px", lg: "0px 100px 100px" } }}>
            <h1>Why Blink?
            </h1>

            <Grid container className='d-flex gap-10 justify-center p-4 '  >

                {
                    props.data2.map((item, index) => {
                        return (
                            <Grid item xs={12} sm={5.8} lg={3.5} key={index} className='relative bg-white shadow-lg p-5 ' sx={{ borderRadius: 5 }}>


                                <h2>{item.title}</h2>
                                <p>{item.description}</p>
                            </Grid>

                        )
                    })
                }
            </Grid>
        </Box>
    )
}
