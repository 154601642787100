import React from 'react'
import Index from '../../components/Index'
import { Box } from "@mui/material";
import Movement from '../../components/international/Movement';
import From from '../../components/international/From';
import image from '../../assets/images/international/international-banner.jpg';
import mobile from '../../assets/images/international/international-banner_mobile.jpg';


export default function international() {
    let data = {
        img : image ,
        mobile : mobile,
        title1 :"Welcome To Emerging Markets",
        title2 :" and Beyond",
        p1 :"We are currently present in more than",
        p2:" eleven countries across three markets",
        direction :"left"
    }
  return (
    <Box sx={{paddingTop:'50px'}}>

        <Index data={data}/>
        <Movement/>
        <From/>

    </Box>
  )
}
