import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { IoIosArrowForward, IoIosAdd, IoIosRemove } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa"; // Import FaGlobe

const DrawerMenu = ({ menuItems, onClose }) => {
  const [expandedItem, setExpandedItem] = useState(null);

  const handleExpand = (text) => {
    setExpandedItem((prev) => (prev === text ? null : text));
  };
  const SocialIcons = () => (
    <Box className="social-icons" sx={{marginBottom:'20px'}}>
      <Link href="#" aria-label="Facebook">
        <FaFacebookF size={20} sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
      </Link>
      <Link href="#" aria-label="Twitter">
        <FaTwitter size={20} sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
      </Link>
      <Link href="#" aria-label="Instagram">
        <FaInstagram size={20} sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
      </Link>
      <Link href="#" aria-label="LinkedIn">
        <FaLinkedin size={20} sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
      </Link>
    </Box>
  );
  return (
    <List sx={{ width: 280, padding: 0, marginTop: "100px" }}>
      {menuItems.map((item) => (
        <div key={item.text}>
          <ListItem
            button
            onClick={() =>
              item.sublinks ? handleExpand(item.text) : onClose()
            }
            sx={{
              borderBottom: "1px solid rgb(232, 234, 243)",
              display: "flex",
              justifyContent: "space-between",
              padding: "12px 16px",
            }}
            component={item.sublinks ? "div" : Link}
            to={item.link} // only adds `to` if there’s no sublink
          >
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 500 }}>{item.text}</Typography>
              }
              sx={{ textAlign: "left", marginRight: "8px" }}
            />
            {item.sublinks ? (
              <IconButton style={{ color: "#000", fontSize: "16px" }}>
                {expandedItem === item.text ? <IoIosRemove /> : <IoIosAdd />}
              </IconButton>
            ) : (
              <IoIosArrowForward style={{ color: "#000", fontSize: "16px" }} />
            )}
          </ListItem>

          {item.sublinks && (
            <Collapse
              in={expandedItem === item.text}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {item.sublinks.map((sublink) => (
                  <ListItem
                    button
                    key={sublink.text}
                    onClick={onClose}
                    component={Link}
                    to={sublink.link}
                    sx={{ pl: 4, padding: "8px 16px" }}
                  >
                    <ListItemText primary={sublink.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    }}>
      <Typography 
        href="mailto:customerservice@imile.me" 
        sx={{
          color: 'rgb(166, 166, 185)', 
          fontSize: '12px',
          textDecoration: 'none',  // Remove underline from the email link
          marginBottom: '8px',
          '&:hover': {
            color: '#fff303',  // Hover effect for color
          }
        }}
      >
        Email: customerservice@imile.me
      </Typography>

      <Typography 
        sx={{
          color: 'rgb(166, 166, 185)', 
          fontSize: '12px',
          textDecoration: 'none',
          '&:hover': {
            color: '#fff303',  // Hover effect for color
          }
        }}
      >
        Call: +971 600566221
      </Typography>
      <SocialIcons />
    </Box>
     
    </List>
  );
};

export default DrawerMenu;
