import { Box } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";

export default function Index() {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    {
      title: "Delivery you can trust",
      subtitle: "“We Got This”",
      description: "e-commerce logistics and last-mile delivery specialists",
    },
    {
      title: "Cash on Delivery Remittance",
      subtitle: "“We Got This”",
      description: "COD remittance is done in less than a week",
    },
    {
      title: "Cross Border Solutions",
      subtitle: "“We Got This”",
      description: "International pick-up and delivery of shipments across borders",
    },
    {
      title: "You Book. We Drop.",
      subtitle: "“We Got This”",
      description: "Door-to-door services for small businesses",
    },
  ];

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <Box className="index" sx={{ height: "90vh" }}>
      <div id="default-carousel" className="relative w-full h-full">
        {/* Carousel wrapper */}
        <div className="relative overflow-hidden rounded-lg h-full">
          {slides.map((slide, index) => (
            <Box
              key={index}
              sx={{
                display: index === activeSlide ? 'flex' : 'none',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                height: '100%',
                padding: { xs: '50px 25px', lg: '100px' },
                transition: 'opacity 0.7s ease-in-out',
              }}
              className={`text-center ${index === activeSlide ? 'opacity-100' : 'opacity-0'}`}
            >
              <h1 className="text-3xl text-white font-bold">
                {slide.title}
                <br /> {slide.subtitle}
              </h1>
              <p className="text-3xl text-white">{slide.description}</p>
            </Box>
          ))}
        </div>

        {/* Slider indicators */}
        <div className="absolute z-30 flex bottom-5 left-1/2 -translate-x-1/2 space-x-3 rtl:space-x-reverse">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`w-3 h-3 rounded-full ${index === activeSlide ? 'bg-blue-500' : 'bg-gray-300'}`}
              onClick={() => setActiveSlide(index)}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>

        {/* Slider controls */}
        <button
          type="button"
          className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={handlePrev}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
            <svg className="w-4 h-4 text-white" aria-hidden="true" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 1 1 5l4 4" />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={handleNext}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
            <svg className="w-4 h-4 text-white" aria-hidden="true" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 9 4-4-4-4" />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    </Box>
  );
}
