import React from 'react';
import { Outlet } from 'react-router-dom';
import "./App.css";
import "./index.css";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div>
      <Header />
      <Outlet /> {/* This will render the nested routes */}
      <Footer />
    </div>
  );
}

export default App;
