import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import App from './App';
import International from './pages/international/international';
import "./App.css"
import Logistics from './pages/logistics/Logistics';
import WhatWeDo from './pages/what-we-do/WhatWeDo';
import Home from './pages/home/Home';
import Error from './pages/error-404/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index element={<Home />} />

      <Route path='/service/international' element={<International />} />
      <Route path='/service/logistics' element={<Logistics />} />
      <Route path='/service/waht-we-do' element={<WhatWeDo />} />
      <Route path='*' element={<Error />} />

    </Route>
  )
);

root.render(
  <RouterProvider router={router} />
);
