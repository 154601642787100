import { Box, Grid } from '@mui/material'
import React from 'react'
import img1 from "../../assets/images/what-we-do/support1@2x.png"
import img2 from "../../assets/images/what-we-do/support2@2x.png"
import img3 from "../../assets/images/what-we-do/support3@2x.png"
import img4 from "../../assets/images/what-we-do/support4@2x.png"



export default function Support() {
    return (
        <Box className="support" sx={{ padding: { xs: "50px 25px", lg: "100px" } }}>
            <h1>Who We Support
            </h1>

            <Grid container sx={{ backgroundColor: "#F6F7FA", display: "flex", justifyContent: "space-between", borderRadius: "32px 0px 0px 32px" }}>

                <Grid item xs={12} lg={5.8} sx={{ display: "flex", gap: "20px", alignItems: "center", flexWrap: { xs: "wrap", lg: "nowrap" } }}>
                    <Box className="left">
                        <img src={img1} alt="..." style={{ width: "600px", height: "350px" }} />

                    </Box>
                    <Box className="right" sx={{ backgroundColor: "#F6F7FA", padding: "20px" }}>
                        <h2 className='font-bold ' style={{ fontSize: "20px" }}>
                            E-commerce and <br />Aggregators

                        </h2>
                        <p style={{ color: "#5B5C73", marginTop: "10px" }}>iMile covers all your 3PL e-commerce requirements from pick up to warehousing and drop off.
                        </p>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={5.8} sx={{ display: "flex", gap: "20px", alignItems: "center", flexWrap: { xs: "wrap", lg: "nowrap" } }}>
                    <Box className="left">
                        <img src={img2} alt="..." style={{ width: "600px", height: "350px" }} />

                    </Box>
                    <Box className="right" sx={{ backgroundColor: "#F6F7FA", padding: "20px" }}>
                        <h2 className='font-bold ' style={{ fontSize: "20px" }}>
                            Retailers


                        </h2>
                        <p style={{ color: "#5B5C73", marginTop: "10px" }}>Our perfect balance of tech and data allows us to understand the needs of our e-commerce landscape better.

                        </p>
                    </Box>
                </Grid>

            </Grid>





            <Grid container sx={{ margin: "40px 0px", backgroundColor: "#F6F7FA", display: "flex", justifyContent: "space-between", borderRadius: "0px 32px 32px 0px" }}>


                <Grid item xs={12} lg={5.8} sx={{ display: "flex", gap: "20px", alignItems: "center", flexWrap: { xs: "wrap", lg: "nowrap" } }}>

                    <Box className="right" sx={{ backgroundColor: "#F6F7FA", padding: "20px" }}>
                        <h2 className='font-bold ' style={{ fontSize: "20px" }}>
                            Social Media <br />Businesses



                        </h2>
                        <p style={{ color: "#5B5C73", marginTop: "10px" }}>iMile provides cost-effective solutions to even the smaller players.


                        </p>
                    </Box>
                    <Box className="left">
                        <img src={img3} alt="..." style={{ width: "600px", height: "350px" }} />

                    </Box>
                </Grid>



                <Grid item xs={12} lg={5.8} sx={{ display: "flex", gap: "20px", alignItems: "center", flexWrap: { xs: "wrap", lg: "nowrap" } }}>

                    <Box className="right" sx={{ backgroundColor: "#F6F7FA", padding: "20px" }}>
                        <h2 className='font-bold ' style={{ fontSize: "20px" }}>
                            Other Verticals


                        </h2>
                        <p style={{ color: "#5B5C73", marginTop: "10px" }}>Our value proposition goes beyond traditional delivery services—emerging markets and beyond.

                        </p>
                    </Box>
                    <Box className="left">
                        <img src={img4} alt="..." style={{ width: "600px", height: "350px" }} />

                    </Box>
                </Grid>

            </Grid>

        </Box>
    )
}
