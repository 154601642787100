import { Box } from '@mui/material';
import React from 'react';
import image1 from "../../assets/images/home/ajex.jpg";
import image2 from "../../assets/images/home/cz.jpg";
import image3 from "../../assets/images/home/fnix.jpg";
import "./style.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Line() {
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Loop the carousel
    speed: 500, // Transition speed in ms
    slidesToShow: 4, // Default: 1 item visible
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Speed for autoplay in ms
    arrows: true, // Show navigation arrows
    responsive: [
      {
        breakpoint: 1024, // For large screens (lg)
        settings: {
          slidesToShow: 4, // Show 4 items
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For medium screens (md)
        settings: {
          slidesToShow: 3, // Show 3 items
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480, // For small screens (sm)
        settings: {
          slidesToShow: 1, // Show 1 item
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  let images = [image1, image2, image3];

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Slider {...settings}>
        {images.map((item, index) => {
          return (
            <Box key={index} className="flex justify-center">
              <img
                src={item}
                alt="..."
                style={{ width: "150px", height: "80px", objectFit: "cover" , display:"block" , margin:"auto" }}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}
