import React from 'react';
import { Typography, Box } from '@mui/material';

const Logo = () => {
  return (
    <Box sx={{ padding: '10px 20px', display: 'flex', alignItems: 'center', height: '90px' }}>
      <Typography variant="h4" sx={{ color: '#fff700', fontWeight: 'bold', fontSize: '32px' }}>B</Typography>
      <Typography variant="h4" sx={{ color: '#ffffff', fontWeight: 'bold', fontSize: '32px' }}>link</Typography>
    </Box>
  );
};

export default Logo;
