import { Box } from '@mui/material'
import React from 'react'
import image1 from "../../assets/images/home/appstore.png"
import image2 from "../../assets/images/home/appgallery.png"
import image3 from "../../assets/images/home/googleplay.png"


export default function Download() {
    let images = [
        image1, image2, image3
    ]
    return (
        <Box className="download" sx={{backgroundColor:"#fff303" , padding:"40px"}}>
            <h1 className='text-start'>DOWNLOAD THE IMILE DELIVERY APP
            </h1>
            <p className='text-xl text-center m-6'>Book and track orders with just a click of a button.
            </p>
            <Box className="flex gap-5" sx={{marginTop:"40px" , flexWrap:"wrap" , justifyContent:{xs:"center" ,lg:"flex-start"}}}>
                {
                    images.map((item, index) => {

                        return (
                            <img src={item} alt="..." key={index}  style={{maxWidth:"170px"}}/>
                        )
                    })
                }
            </Box>
        </Box>
    )
}
