import { Box, Grid } from '@mui/material'
import React from 'react'
import image1 from "../../assets/images/home/solution1.webp"
import image2 from "../../assets/images/home/solution2.webp"
import image3 from "../../assets/images/home/solution3.webp"
import image4 from "../../assets/images/home/solution4.webp"
import "./style.css"
export default function Delivers() {
    let data = [
        {
            img: image1,
            title1: "Reliable and",
            title2: "efficient delivery"
        },
        {
            img: image2,
            title1: "Versatile and",
            title2: "scalable solutions"
        },
        {
            img: image3,
            title1: "Enhanced",
            title2: "customer experience"
        },
        {
            img: image4,
            title1: "Professional",
            title2: "delivery fleet"
        },
    ]
    return (
        <Box className="delivers">
            <h1>Blink Delivers Customized E-commerce Solutions
            </h1>
            <Grid container sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {
                    data.map((item, index) => {

                        return (
                            <Grid item xs={12} sm={5.8} md={3.5} lg={2.5} key={index} sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                                <img src={item.img} alt="..." style={{ width: "180px" }} />
                                <p className='font-bold'>{item.title1}</p>
                                <p className='font-bold'>{item.title2}</p>

                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
