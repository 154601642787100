import React, { useEffect, useState } from 'react';
import { Box, CardMedia } from '@mui/material';
import "./international/style.css";

export default function Index(props) {
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial screen size

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Box className="index relative  sm:h-screen" sx={{ backgroundColor: "white", height: { sm: "auto", lg: "90vh" } }}>
            <Box className="relative sm:h-screen">
                <CardMedia
                    component="img"
                    alt="International Banner"
                    className=" sm:h-screen w-screen object-cover"
                    src={isLargeScreen ? props.data.img : props.data.mobile}
                />
                {/* Overlay Layer */}
                <Box
                    className={`layer absolute inset-0 flex flex-col ${props.data.direction === "left" ? "text-left items-start" : "text-right items-end"} text-white p-4`}
                    sx={{ padding: { xs: "100px 25px", lg: "100px" } }}
                >
                    <h1 className="text-3xl font-bold mb-4">
                        {props.data.title1}
                        <br />
                        {props.data.title2}
                    </h1>
                    <p className="text-lg">
                        {props.data.p1}
                        <br />
                        {props.data.p2}
                    </p>
                </Box>
            </Box>
        </Box>
    );
}
