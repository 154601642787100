import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, IconButton, Drawer } from "@mui/material";
import Logo from "./Logo"; // Assuming your Logo component
import TopBar from "./TopBar"; // The top bar with contact info
import NavMenu from './NavMenu';
import DrawerMenu from '../Drawer/Drawer';
import { CgMenuRight } from "react-icons/cg";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState('#095ef7');

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Effect to handle scroll event and change header color
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderColor('#000417');
      } else {
        setHeaderColor('#095ef7');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Mobile drawer menu items
  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'About Us', link: '/about' },
    { 
      text: 'Services', 
      link: '#',
      sublinks: [
        { text: 'What We Do', link: '/service/waht-we-do' },
        { text: 'Logistics Products', link: '/service/logistics' },
        { text: 'International & China', link: '/service/international' }
      ]
    },
    { text: 'Technology', link: '/technology' },
    { text: 'CSP', link: '/csp' },
    { text: 'Track Orders', link: '/track' },
    { text: 'Blog', link: '/blog' },
    { text: 'Login', link: '/login' },
  ];
  
  return (
    <header className='shadow'>
      <Box
        sx={{
          backgroundColor: headerColor,
          transition: 'background-color 0.3s ease',
          width: '100%',
        }}
      >
        <AppBar
          position="fixed" // Keeps header sticky at the top
          elevation={0}
          sx={{
            backgroundColor: headerColor,
            zIndex: (theme) => theme.zIndex.drawer + 1, // Ensures drawer starts below the header
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between", paddingX: 2 }}>
            {/* Logo section */}
            <Logo />

            {/* TopBar and Navigation Menu for Desktop */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <TopBar />
              <NavMenu toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
            </Box>

            {/* Hamburger menu for Mobile */}
            <Box sx={{ display: { xs: 'block', sm: 'block',md:'none',lg:'none' } }}>
              <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
                <CgMenuRight />
              </IconButton>

              {/* Drawer for mobile menu */}
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer}
                sx={{ marginTop: '64px' }} // Ensure the drawer opens below the AppBar
              >
                <DrawerMenu menuItems={menuItems} onClose={toggleDrawer} />
              </Drawer>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
};

export default Header;
